<script>
import FilterHeader from '@comp/project/FilterHeader.vue'
import IndexChoicePopup from '@comp/project/IndexChoicePopup.vue'
import NewDocPopup from '@comp/project/NewDocPopup.vue'
import ProjectTable from '@comp/project/ProjectTable.vue'
import {
  STEP_END_OF_WORKFLOW,
  WORKFLOW_STEPS,
  FROM_HISTORY,
  FROM_PROJECT,
} from '@src/utils/consts'
import {
  currentProject,
  allDocumentVersions,
} from '@comp/project/queries'
import { getSafeMetadataName } from '@src/utils/utils'
import { archiveRoute, documentRoute, projectRoute } from '@src/router/index'
import { useIndexStore } from '@src/store/index'
import gql from 'graphql-tag'

export default {
  name: 'Project',
  components: {
    IndexChoicePopup,
    NewDocPopup,
    ProjectTable,
    FilterHeader,
  },
  props: {
    archive: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      isDownloading: false,
      projectId: null,
      error: null,
      totalDocuments: 0,
      sortOptions: {
        sortBy: [],
      },
      ready: false,
      dialogNewDoc: false,
      search: [],
      permissions: null,
      metadata: [],
      trades: [],
      contracts: [],
      steps: [],
      page: 1,
      documentVersions: [],
      indexChoicePopup: false,
      documentIndexes: [],
      callTimeoutId: null,
      filtersPanel: true,
      showFiltersContent: true,
      getDataError: false,
      filters: {
        trades: {}, // key: str(id), value: bool
        contracts: {}, // key: str(id), value: bool
        steps: {}, // key: stepName, value: bool
        tradesFilterValue: 'all',
        keywords: [], // strings
      },
      itemsPerPage: 20,
    }
  },
  computed: {
    store () {
      return useIndexStore()
    },
    displayedTrades () {
      if (Object.values(this.filters.trades).some(value => value)) {
        return this.trades.filter(trade => this.filters.trades[trade.id])
      } else {
        return this.trades.slice()
      }
    },
    filterableSteps () {
      return this.steps.filter(o => o.type !== STEP_END_OF_WORKFLOW)
    },
  },
  watch: {
    sortOptions: {
      async handler () {
        if (this.projectId) {
          this.updateStoreSort()
          await this.getData()
        }
      },
    },
    filters: {
      async handler () {
        await this.getData()
      },
    },
    archive: {
      async handler () {
        await this.getData()
        this.updateStoreRoute()
      },
    },
  },
  async mounted () {
    this.updateStoreRoute()
    this.projectId = this.$route.params.id
    this.setFiltersFromStore()
    this.steps = Object.keys(WORKFLOW_STEPS).map(o => ({
      id: WORKFLOW_STEPS[o].id,
      label: WORKFLOW_STEPS[o].label,
      type: o,
      value: false,
    }))
    await this.getHeadersAndFilters()
    this.setSortFromStore()
    this.setFiltersPanelVisibilityFromStore()
    this.ready = true
    if (this.permissions && this.permissions.doIHaveAccess) {
      this.error = null
    } else {
      this.error = this.$gettext('You do not have access to this project.')
    }
    await this.getData(true)
  },
  methods: {
    updateStoreRoute () {
      this.store.changeRoute(this.archive ? archiveRoute.name : projectRoute.name)
    },
    onUpdateFilters (filters) {
      this.filters = filters
    },
    updateStoreSort () {
      this.store.changeTableParam({
        key: 'sort',
        newValue: {
          sortBy: this.sortOptions.sortBy,
        },
      })
    },
    getStoreValueForProjectAndRoute (storeVariableObj) {
      if (storeVariableObj) {
        const valueForProject = storeVariableObj[this.projectId]
        if (valueForProject && valueForProject[this.store.route]) {
          return valueForProject[this.store.route]
        }
      }
    },
    setSortFromStore () {
      const storeSort = this.getStoreValueForProjectAndRoute(this.store.sort)
      if (storeSort) {
        this.sortOptions.sortBy = storeSort.sortBy
      }
    },
    setFiltersFromStore () {
      const storeFilters = this.getStoreValueForProjectAndRoute(this.store.filters)
      if (storeFilters) {
        for (const key in storeFilters) {
          this.filters[key] = storeFilters[key]
        }
      }
    },
    setFiltersPanelVisibilityFromStore () {
      this.filtersPanel = this.store.filtersPanel
      this.showFiltersContent = this.filtersPanel
    },
    compareTrades (a, b) {
      return b.experts.some(o => o.id === this.store.user.id) - a.experts.some(o => o.id === this.store.user.id)
    },
    filterValues (filters) {
      return Object.entries(filters)
        .filter(([_, value]) => value)
    },
    generateFilters () {
      const selectedTrades = this.filterValues(this.filters.trades).map(([key, _]) => parseInt(key))
      const selectedContracts = this.filterValues(this.filters.contracts).map(([key, _]) => parseInt(key))
      const selectedSteps = this.filterValues(this.filters.steps).map(([key, _]) => key)
      const searchValues = this.filters.search || []
      const baseFilter = `document: {
        project: {id: {exact: ${this.projectId}}}`
      let filter = `${baseFilter}`
      if (selectedContracts.length) {
        filter += `\ncontract: {id: {inList: ${JSON.stringify(selectedContracts)}}}`
      }
      filter += '\n}'
      filter += '\nonlyLatestVersion: true'
      if (!this.archive) {
        filter += '\nonlyInLastStep: false'
      }
      switch (this.filters.tradesFilterValue) {
        case 'completed':
          filter += '\nallEvaluationsDone: true'
          break
        case 'in_progress':
          filter += '\nallEvaluationsDone: false'
          break
      }
      if (selectedTrades.length) {
        filter += `\ntradeIds: {inList: ${JSON.stringify(selectedTrades)}}`
      }
      if (selectedSteps.length) {
        filter += `\ncurrentStep: {template: {type: {inList: ${JSON.stringify(selectedSteps)}}}}`
      }
      if (searchValues.length) {
        filter += `\nmetadataValues: {inList: ${JSON.stringify(searchValues)}}`
      }
      return `filters: {${filter}}`
    },
    generateOrder () {
      const { sortBy } = this.sortOptions
      if (sortBy && sortBy.length) {
        let sortCriteria
        const sortField = sortBy[0]['key']
        const order = sortBy[0]['order'].toUpperCase()
        switch (sortField) {
          case 'title':
          case 'workflowStartDatetime':
          case 'remainingStepCalendarDays':
          case 'endOfWorkflowTransitionDatetime':
            sortCriteria = `${sortField}: ${order}`
            break
          case 'document.contract.code':
            sortCriteria = `document: {contract: {code: ${order}}}`
            break
          default:
            const sortKey = sortField.replace('metadataValues.', '')
            const metadata = this.metadata.find(m => getSafeMetadataName(m.name) === sortKey)
            sortCriteria = metadata ? `metadata: {id: ${metadata.id}, order: ${order}}` : `${sortField}: ${order}`
            break
        }
        return `order: {${sortCriteria}, id: ${order}}`
      }
      return `order: { id: ASC }`
    },
    generatePagination () {
      return `pagination: {limit: ${this.itemsPerPage}, offset: ${(this.page - 1) * this.itemsPerPage}}`
    },
    constructQuery () {
      const filterPattern = this.generateFilters()
      return allDocumentVersions
        .replace('##REPLACE##', `(\n${filterPattern}\n${this.generateOrder()}\n${this.generatePagination()}\n)`)
        .replace('##REPLACE_COUNT##', `(${filterPattern})`)
    },
    async getData () {
      this.getDataError = false
      if (!this.isDownloading) {
        await this.downloadDocuments()
      }
    },
    async downloadDocuments () {
      this.isDownloading = true
      try {
        const response = await this.$graphqlQuery(gql`${this.constructQuery()}`)
        this.handleDocumentVersionsResponse(response)
      } catch (e) {
        this.store.changeNotification({
          type: 'error',
          text: e,
          autoClose: false,
        })
        this.getDataError = true
      }
      this.isDownloading = false
    },
    updateDocumentVersionList (documentVersions) {
      this.documentVersions = [...documentVersions]
    },
    parseDocumentVersion (documentVersion) {
      documentVersion.metadata = documentVersion.metadataValues.map(
        metadatum => ({ name: metadatum.metadata.name, value: metadatum.value }),
      )
      documentVersion.metadataValues = {}
      for (const metadatum of documentVersion.metadata) {
        documentVersion.metadataValues[getSafeMetadataName(metadatum.name)] = metadatum.value
      }
      documentVersion.trades = {}
      for (const tradeContribution of documentVersion.tradeContributions) {
        documentVersion.trades[tradeContribution.trade.acronym] = tradeContribution.status
      }
      return documentVersion
    },
    handleDocumentVersionsResponse (response) {
      const documentVersions = response.allDocumentVersions
      documentVersions.map(documentVersion => this.parseDocumentVersion(documentVersion))
      this.totalDocuments = response.countDocumentVersions.totalCount
      this.updateDocumentVersionList(documentVersions)
    },
    async getHeadersAndFilters () {
      return this.$graphqlQuery(currentProject, {
        id: this.projectId,
      }).then(response => {
        const project = response.project
        this.permissions = project.permissions
        this.metadata = project.metadata
        this.trades = project.trades.sort(this.compareTrades)
        this.contracts = project.contracts
        this.store.changeProject(project)
      })
    },
    async onCloseAddDialog () {
      this.dialogNewDoc = false
      await this.getData()
    },
    onClickUploadNewDocument () {
      this.dialogNewDoc = true
    },
    onResizeFilters () {
      this.filtersPanel = !this.filtersPanel
      this.store.changeVisibility('filtersPanel')
    },
    onDocumentViewRequest (documentVersion) {
      if (this.archive) {
        const versionsNumber = documentVersion.document.versions.length
        if (versionsNumber > 1) {
          this.documentIndexes = documentVersion.document.versions
          this.documentIndexes.sort((a, b) => a.index - b.index)
          this.indexChoicePopup = true
        } else {
          this.$router.push({
            name: documentRoute.name,
            params: { id: documentVersion.id },
            query: {
              from: FROM_HISTORY,
            },
          })
        }
      } else {
        this.$router.push({
          name: documentRoute.name,
          params: { id: documentVersion.id },
          query: {
            from: FROM_PROJECT,
          },
        })
      }
    },
    onUpdateItemsPerPage (value) {
      this.itemsPerPage = value
      this.getData()
    },
    onUpdateOptions (value) {
      this.page = value.page
      this.sortOptions.sortBy = value.sortBy
      this.itemsPerPage = value.itemsPerPage
      this.getData()
    },
  },
}
</script>
<template>
  <section
    v-if="ready"
    class="view project"
  >
    <div
      v-if="error"
      class="bg-error"
    >
      {{ $gettext(error) }}
    </div>
    <div v-if="!error">
      <FilterHeader
        :filters-panel="filtersPanel"
        :error="getDataError"
        :disabled="isDownloading"
        :archive="archive"
        :trades="trades"
        :contracts="contracts"
        :steps="filterableSteps"
        :project-id="projectId"
        @resize-filters="onResizeFilters"
        @update-filters="onUpdateFilters"
      />
    </div>
    <ProjectTable
      v-if="!error"
      v-model:page="page"
      :archive="archive"
      :document-versions="documentVersions"
      :error="error"
      :items-per-page="itemsPerPage"
      :loading="isDownloading"
      :metadata="metadata"
      :steps="steps"
      :total-documents="totalDocuments"
      :trades="displayedTrades"
      :sort-options="sortOptions"
      @document-view-request="onDocumentViewRequest"
      @upload-new-document="onClickUploadNewDocument"
      @update-items-per-page="onUpdateItemsPerPage"
      @update-options="onUpdateOptions"
    />
    <NewDocPopup
      v-if="dialogNewDoc"
      :metadata="metadata"
      :contracts="contracts"
      @close-add-dialog="onCloseAddDialog"
    />
    <IndexChoicePopup
      :document-indexes="documentIndexes"
      :index-choice-popup="indexChoicePopup"
      @close-popup="indexChoicePopup = false"
    />
  </section>
  <section
    v-else
    class="view loading-view"
  />
</template>
<style lang="scss" scoped>
.project {
  flex-direction: column;
}
</style>

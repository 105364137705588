import { createRouter, createWebHistory } from 'vue-router'
import { checkAuth } from '@src/utils/auth'
import { useIndexStore } from '@src/store'

// Pages
import Home from '@page/Home.vue'
import Login from '@page/Login.vue'
import Fail from '@page/Fail.vue'
import User from '@page/User.vue'
import Document from '@page/Document.vue'
import ProjectSettings from '@page/ProjectSettings.vue'
import Project from '@page/Project.vue'
import PageNotFound from '@page/PageNotFound.vue'

// only used to force to see translation to vue-gettext
const $gettext = s => s

// Auth pages start
const loginPath = '/login'
export const ifAuthenticated = async (_to, _from, next) => {
  const user = await checkAuth()
  if (user) {
    useIndexStore().changeUser(user)
    next()
  } else {
    next(loginPath)
  }
}
export const loginRoute = {
  path: loginPath,
  name: 'Login',
  component: Login,
}
export const failRoute = {
  path: '/fail',
  name: 'Fail',
  component: Fail,
}
export const userRoute = {
  path: '/user',
  name: 'User',
  component: User,
  beforeEnter: ifAuthenticated,
}
export const disconnectRoute = { /* no path, no component, just a link in the menu */
  name: 'Disconnect',
  icon: 'fas fa-sign-out-alt',
  title: $gettext('Logout'),
}
// Auth pages end

export const homeRoute = {
  path: '/',
  name: 'Home',
  component: Home,
  icon: 'fas fa-house',
  title: $gettext('Home'),
  beforeEnter: [ifAuthenticated],
}
export const documentRoute = {
  path: '/documentVersion/:id',
  name: 'DocumentVersion',
  component: Document,
  title: $gettext('Document'),
  beforeEnter: ifAuthenticated,
  generatePushOptions: (store, $route) => {
    return {
      name: 'DocumentVersion',
      params: {
        id: $route.params.id,
      },
    }
  },
}
export const projectSettingsRoute = {
  path: '/projectSettings/:id',
  name: 'ProjectSettings',
  component: ProjectSettings,
  icon: 'fas fa-edit',
  title: $gettext('Project settings'),
  beforeEnter: ifAuthenticated,
  needProject: true,
  generatePushOptions: store => {
    return {
      name: 'ProjectSettings',
      params: {
        id: store.project.id,
      },
    }
  },
}
export const projectRoute = {
  path: '/project/:id',
  name: 'Project',
  component: Project,
  props: { archive: false },
  icon: 'fas fa-table',
  title: $gettext('Project'),
  beforeEnter: ifAuthenticated,
  needProject: true,
  generatePushOptions: store => {
    return {
      name: 'Project',
      params: {
        id: store.project.id,
      },
    }
  },
}
export const archiveRoute = {
  path: '/archive/:id',
  name: 'Archive',
  component: Project,
  props: { archive: true },
  icon: 'fas fa-clipboard-check',
  title: $gettext('Archive'),
  beforeEnter: ifAuthenticated,
  needProject: true,
  generatePushOptions: store => {
    return {
      name: 'Archive',
      params: {
        id: store.project.id,
      },
    }
  },
}

// OTHERS
export const pageNotFound = {
  path: '/:pathMatch(.*)',
  name: 'PageNotFound',
  component: PageNotFound,
}

export const routes = [
  homeRoute,
  documentRoute,
  projectSettingsRoute,
  projectRoute,
  archiveRoute,
  userRoute,
  failRoute,
  loginRoute,
  pageNotFound,
]
export const fullscreenRoutes = [
  loginRoute,
  failRoute,
  pageNotFound,
]
export const navigationRoutes = [
  homeRoute,
  projectRoute,
  archiveRoute,
  disconnectRoute,
]
export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

<script>
import ProjectCard from '@comp/home/ProjectCard.vue'
import { useIndexStore } from '@src/store'
import { projectRoute } from '@src/router'
import {
  askProjectPerms,
  deleteProject,
  getAllProjects,
  openProject,
} from '@comp/home/queries'

export default {
  name: 'ProjectList',
  components: {
    ProjectCard,
  },
  data () {
    return {
      projects: [],
    }
  },
  computed: {
    store () {
      return useIndexStore()
    },
    createdProject () {
      return this.store.createdProject
    },
    isAdmin () {
      const user = this.store.user
      return user && !!user.isAdmin
    },
  },
  watch: {
    createdProject () {
      this.projects.push(this.store.createdProject)
    },
  },
  async mounted () {
    await this.setData()
  },
  methods: {
    async setData () {
      await this.$graphqlQuery(
        getAllProjects,
      ).then(response => {
        this.projects.push(...response.allProjects)
      }).catch(console.error)
    },
    async onEnterProject (project) {
      if (project.isPublished) {
        const partialProject = await this.$graphqlQuery(askProjectPerms, { id: project.id })
          .then(response => {
            return response.project
          }).catch(error => {
            this.store.changeNotification({
              type: 'error',
              text: error,
              autoClose: false,
            })
          })
        if (!partialProject) {
          return
        }
        if (!partialProject.permissions.doIHaveAccess) {
          const error = this.$gettext('You don’t have sufficient rights to open this project')
          this.store.changeNotification({
            type: 'error',
            text: error,
            autoClose: false,
          })
          return
        }
        const fullProject = await this.$graphqlQuery(openProject, { id: project.id })
          .then(response => {
            project.docControllers = response.project.docControllers
            project.permissions = response.project.permissions
            return project
          }).catch(error => {
            this.store.changeNotification({
              type: 'error',
              text: error,
              autoClose: false,
            })
          })
        if (!fullProject) {
          return
        }
        this.store.changeProject(fullProject)
        this.$router.push({
          name: projectRoute.name,
          params: {
            id: fullProject.id,
          },
        })
      }
    },
    onDeleteProject (project) {
      return this.$graphqlMutate(deleteProject, { id: project.id })
        .then(response => {
          this.projects = this.projects.filter(o => o.id !== response.deleteProject.id)
        }).catch(error => {
          this.store.changeNotification({
            type: 'error',
            text: error,
            autoClose: false,
          })
        })
    },
  },
}
</script>
<template>
  <section class="projects">
    <div class="project-cards-container">
      <ProjectCard
        v-for="project in projects"
        :key="project.id"
        :item="project"
        :is-admin="isAdmin"
        @enter-project="onEnterProject"
        @delete-project="onDeleteProject"
      />
    </div>
  </section>
</template>
<style lang="scss" scoped>
.projects {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: 100%;
  min-height: 10%;
}
.project-cards-container {
  display: flex;
  flex-direction: column;
  gap: 1ch;
  max-height: 100%;
  overflow-y: auto;
  padding-right: 5px;
  padding-bottom: 10px;
}
</style>
